import {
  Instance_default
} from "./chunk-FMKADM4B.js";
import {
  BaseRoute_default
} from "./chunk-TY443UAN.js";

// src/PersonaTemplate/PersonaTemplateRoute.ts
var PersonaTemplateRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    /**
     * function getPersonaRequest get specified Persona Data from Aesir Redcore WS
     * @param  personaTemplateId
     * @return JSON
     */
    this.getPersonaTemplateRequest = (personaTemplateId) => Instance_default.get(
      this.createRequestURL({
        option: "persona_template",
        id: personaTemplateId
      })
    );
    this.getPersonaTemplatesRequest = (page = 1, limit = 20) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "persona_template",
          "list[limitstart]": (page - 1) * limit,
          "list[limit]": limit
        })
      );
    };
  }
};
var PersonaTemplateRoute_default = PersonaTemplateRoute;

export {
  PersonaTemplateRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
