import {
  Storage
} from "./chunk-GYE2SV7H.js";
import {
  AUTHORIZATION_KEY,
  AXIOS_CONFIGS
} from "./chunk-4UXUCZ6D.js";

// src/Gateway/InstanceServiceApi.ts
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
var baseUrl = process.env.BASE_ENDPOINT_SERVICE_URL !== void 0 && process.env.BASE_ENDPOINT_SERVICE_URL !== "" ? process.env.BASE_ENDPOINT_SERVICE_URL : AXIOS_CONFIGS.BASE_ENDPOINT_SERVICE_URL;
var AesirxServiceApiInstance = axios.create({
  baseURL: baseUrl,
  timeout: 80 * 1e3
});
var clientID = process.env.CUSTOM_SERVICE_CLIENT_ID !== void 0 && process.env.CUSTOM_SERVICE_CLIENT_ID !== "" ? process.env.CUSTOM_SERVICE_CLIENT_ID : AXIOS_CONFIGS.CUSTOM_SERVICE_CLIENT_ID;
var clientSecret = process.env.CUSTOM_SERVICE_CLIENT_SECRET !== void 0 && process.env.CUSTOM_SERVICE_CLIENT_SECRET !== "" ? process.env.CUSTOM_SERVICE_CLIENT_SECRET : AXIOS_CONFIGS.CUSTOM_SERVICE_CLIENT_SECRET;
var reqAuthFormData;
if (true) {
  reqAuthFormData = new FormData();
  reqAuthFormData.append("grant_type", "client_credentials");
  reqAuthFormData.append("client_id", clientID);
  reqAuthFormData.append("client_secret", clientSecret);
} else {
  reqAuthFormData = {
    grant_type: "client_credentials",
    client_id: clientID,
    client_secret: clientSecret
  };
}
var requestANewLaravelCustomServiceAccessToken = () => {
  return AesirxServiceApiInstance.post("/oauth/token", reqAuthFormData, {}).then(
    (tokenRefreshResponse) => {
      let authorizationHeader = "";
      let tokenType = "";
      let accessToken = "";
      if (tokenRefreshResponse) {
        tokenType = tokenRefreshResponse.token_type ?? "Bearer";
        accessToken = tokenRefreshResponse.access_token ?? "";
        authorizationHeader = authorizationHeader.concat(tokenType).concat(" ").concat(accessToken);
      }
      if (false) {
        process.env.AUTHORIZED_TOKEN_CUSTOM_SERVICE = accessToken;
      } else {
        Storage.setItem(AUTHORIZATION_KEY.CUSTOM_SERVICE_ACCESS_TOKEN, accessToken);
        Storage.setItem(AUTHORIZATION_KEY.CUSTOM_SERVICE_TOKEN_TYPE, tokenType);
        Storage.setItem(
          AUTHORIZATION_KEY.CUSTOM_SERVICE_AUTHORIZED_TOKEN_HEADER,
          authorizationHeader
        );
      }
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
var refreshLaravelCustomServiceAuthLogic = () => {
  return requestANewLaravelCustomServiceAccessToken();
};
createAuthRefreshInterceptor(AesirxServiceApiInstance, refreshLaravelCustomServiceAuthLogic, {
  statusCodes: [401, 403],
  pauseInstanceWhileRefreshing: true
});
var pending = {};
var removePending = (config, f) => {
  if (config) {
    const url = config.url.replace(config.baseURL, "/");
    const flagUrl = url + "&" + config.method + "&" + JSON.stringify(config.params);
    if (flagUrl in pending) {
      if (f) {
        f();
      } else {
        delete pending[flagUrl];
      }
    } else {
      if (f) {
        pending[flagUrl] = f;
      }
    }
  }
};
AesirxServiceApiInstance.interceptors.request.use(
  function(config) {
    let accessToken = "";
    if (false) {
      accessToken = process.env.AUTHORIZED_TOKEN_CUSTOM_SERVICE;
      if (accessToken) {
        config.headers.Authorization = "Bearer " + accessToken;
      }
    } else {
      accessToken = Storage.getItem(AUTHORIZATION_KEY.CUSTOM_SERVICE_ACCESS_TOKEN);
      const authorizationHeader = Storage.getItem(
        AUTHORIZATION_KEY.CUSTOM_SERVICE_AUTHORIZED_TOKEN_HEADER
      );
      if (authorizationHeader) {
        config.headers.Authorization = authorizationHeader;
      }
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
AesirxServiceApiInstance.interceptors.response.use(
  (response) => {
    removePending(response.config, "");
    return response.data;
  },
  (error) => {
    removePending(error.config, "");
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
var InstanceServiceApi_default = AesirxServiceApiInstance;

export {
  requestANewLaravelCustomServiceAccessToken,
  InstanceServiceApi_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
