import {
  AesirxAuthenticationApiService
} from "./chunk-FMKADM4B.js";
import {
  Storage
} from "./chunk-GYE2SV7H.js";
import {
  BaseRoute_default
} from "./chunk-TY443UAN.js";
import {
  AUTHORIZATION_KEY,
  AXIOS_CONFIGS
} from "./chunk-4UXUCZ6D.js";

// src/Gateway/IntanceWebServiceApi.ts
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import queryString from "query-string";
var AUTHORIZED_CODE_URL = BaseRoute_default.__createRequestURL(
  {
    option: "token",
    api: "oauth2"
  },
  false,
  AXIOS_CONFIGS.WEBSERVICE_ENDPOINT_URL
);
var AesirxWebServiceApiInstance = axios.create({
  baseURL: AXIOS_CONFIGS.WEBSERVICE_ENDPOINT_URL,
  timeout: 30 * 1e3
});
var clientID = process.env.WEBSERVICE_CLIENT_ID !== void 0 && process.env.WEBSERVICE_CLIENT_ID !== "" ? process.env.WEBSERVICE_CLIENT_ID : AXIOS_CONFIGS.WEBSERVICE_CLIENT_ID;
var clientSecret = process.env.WEBSERVICE_CLIENT_SECRET !== void 0 && process.env.WEBSERVICE_CLIENT_SECRET !== "" ? process.env.WEBSERVICE_CLIENT_SECRET : AXIOS_CONFIGS.WEBSERVICE_CLIENT_SECRET;
var reqAuthFormData = new FormData();
reqAuthFormData.append("grant_type", "client_credentials");
reqAuthFormData.append("client_id", clientID);
reqAuthFormData.append("client_secret", clientSecret);
var refreshToken = (failedRequest) => {
  const request = new AesirxAuthenticationApiService();
  const key = {
    [AUTHORIZATION_KEY.ACCESS_TOKEN]: [AUTHORIZATION_KEY.WEBSERVICE_ACCESS_TOKEN],
    [AUTHORIZATION_KEY.TOKEN_TYPE]: [AUTHORIZATION_KEY.WEBSERVICE_TOKEN_TYPE],
    [AUTHORIZATION_KEY.AUTHORIZED_TOKEN_HEADER]: [
      AUTHORIZATION_KEY.WEBSERVICE_AUTHORIZED_TOKEN_HEADER
    ]
  };
  request.refreshToken(failedRequest, AUTHORIZED_CODE_URL, reqAuthFormData, key);
};
var refreshAuthLogic = (failedRequest) => {
  return refreshToken(failedRequest);
};
createAuthRefreshInterceptor(AesirxWebServiceApiInstance, refreshAuthLogic, {
  statusCodes: [401, 403],
  pauseInstanceWhileRefreshing: true
});
var pending = {};
var CancelToken = axios.CancelToken;
var removePending = (config, f) => {
  if (config) {
    const url = config.url.replace(config.baseURL, "/");
    const flagUrl = url + "&" + config.method + "&" + JSON.stringify(config.params);
    if (flagUrl in pending) {
      if (f) {
        f();
      } else {
        delete pending[flagUrl];
      }
    } else {
      if (f) {
        pending[flagUrl] = f;
      }
    }
  }
};
AesirxWebServiceApiInstance.interceptors.request.use(
  function(config) {
    let accessToken = "";
    if (false) {
      accessToken = process.env.AUTHORIZED_TOKEN_CUSTOM_SERVICE;
      if (accessToken) {
        config.headers.Authorization = "Bearer " + accessToken;
      }
    } else {
      accessToken = Storage.getItem(AUTHORIZATION_KEY.WEBSERVICE_ACCESS_TOKEN);
      if (accessToken) {
        config.url = config?.url.concat("&").concat(queryString.stringify({ access_token: accessToken }));
      }
    }
    config.cancelToken = new CancelToken((c) => {
      removePending(config, c);
    });
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
AesirxWebServiceApiInstance.interceptors.response.use(
  (response) => {
    removePending(response.config, "");
    return response.data;
  },
  (error) => {
    removePending(error.config, "");
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
var IntanceWebServiceApi_default = AesirxWebServiceApiInstance;

export {
  IntanceWebServiceApi_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
