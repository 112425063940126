// src/env.ts
if (typeof window !== "undefined") {
  window.process = { env: "" };
}
var s = false ? [] : typeof window !== "undefined" && window;
var customEnv = {
  REACT_APP_ENDPOINT_URL: process.env.REACT_APP_ENDPOINT_URL,
  NEXT_PUBLIC_ENDPOINT_URL: process.env.NEXT_PUBLIC_ENDPOINT_URL,
  REACT_APP_SSO_CLIENT_ID: process.env.REACT_APP_SSO_CLIENT_ID,
  NEXT_PUBLIC_SSO_CLIENT_ID: process.env.NEXT_PUBLIC_SSO_CLIENT_ID,
  REACT_APP_SSO_CLIENT_SECRET: process.env.REACT_APP_SSO_CLIENT_SECRET,
  NEXT_PUBLIC_SSO_CLIENT_SECRET: process.env.NEXT_PUBLIC_SSO_CLIENT_SECRET,
  REACT_APP_SSO_CONCORDIUM_NETWORK: process.env.REACT_APP_SSO_CONCORDIUM_NETWORK,
  NEXT_PUBLIC_CONCORDIUM_NETWORK: process.env.NEXT_PUBLIC_CONCORDIUM_NETWORK,
  REACT_APP_WEB3_API_ENDPOINT: process.env.REACT_APP_WEB3_API_ENDPOINT,
  NEXT_PUBLIC_WEB3_API_ENDPOINT: process.env.NEXT_PUBLIC_WEB3_API_ENDPOINT,
  REACT_APP_WEB3_DAPP_URL: process.env.REACT_APP_WEB3_DAPP_URL,
  NEXT_PUBLIC_DAPP_URL: process.env.NEXT_PUBLIC_DAPP_URL,
  REACT_APP_PARTNERS_URL: process.env.REACT_APP_PARTNERS_URL,
  NEXT_PUBLIC_PARTNERS_URL: process.env.NEXT_PUBLIC_PARTNERS_URL,
  REACT_APP_SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  NEXT_PUBLIC_SOCKET_URL: process.env.NEXT_PUBLIC_SOCKET_URL,
  REACT_APP_USERNAME: process.env.REACT_APP_USERNAME,
  NEXT_PUBLIC_USERNAME: process.env.NEXT_PUBLIC_USERNAME,
  REACT_APP_FIRSTNAME: process.env.REACT_APP_FIRSTNAME,
  NEXT_PUBLIC_FIRSTNAME: process.env.NEXT_PUBLIC_FIRSTNAME,
  REACT_APP_LASTNAME: process.env.REACT_APP_LASTNAME,
  NEXT_PUBLIC_LASTNAME: process.env.NEXT_PUBLIC_LASTNAME,
  REACT_APP_PRODUCT: process.env.REACT_APP_PRODUCT,
  NEXT_PUBLIC_PRODUCT: process.env.NEXT_PUBLIC_PRODUCT,
  REACT_APP_EMAIL: process.env.REACT_APP_EMAIL,
  NEXT_PUBLIC_EMAIL: process.env.NEXT_PUBLIC_EMAIL,
  REACT_APP_ORGANIZATION: process.env.REACT_APP_ORGANIZATION,
  NEXT_PUBLIC_ORGANIZATION: process.env.NEXT_PUBLIC_ORGANIZATION,
  REACT_APP_MESSAGE: process.env.REACT_APP_MESSAGE,
  NEXT_PUBLIC_MESSAGE: process.env.NEXT_PUBLIC_MESSAGE,
  REACT_APP_ORDER_ID: process.env.REACT_APP_ORDER_ID,
  NEXT_PUBLIC_ORDER_ID: process.env.NEXT_PUBLIC_ORDER_ID,
  REACT_APP_CODE: process.env.REACT_APP_CODE,
  NEXT_PUBLIC_CODE: process.env.NEXT_PUBLIC_CODE,
  REACT_APP_BI_ENDPOINT_URL: process.env.REACT_APP_BI_ENDPOINT_URL,
  REACT_APP_ENDPOINT_ANALYTICS_URL: process.env.REACT_APP_ENDPOINT_ANALYTICS_URL,
  REACT_APP_DATA_STREAM: process.env.REACT_APP_DATA_STREAM,
  REACT_APP_HEADER_JWT: process.env.REACT_APP_HEADER_JWT,
  REACT_APP_WOOCOMMERCE_MENU: process.env.REACT_APP_WOOCOMMERCE_MENU
};
var env = { ...customEnv, ...process.env, ...s["env"] };

export {
  env
};
